import React from 'react';
import { motion } from "framer-motion";
import { Animation }  from '../../Global';
import { Helmet } from "react-helmet";
import BackHome from '../../components/BackHome';


//Page data
const Page = {
  title: "Don't count your screen reader users."
}

//COMPONENT
function DontCountYourScreenReaderUsers() {
    return (
      <motion.div initial="initial" animate="in" exit="out" variants={Animation.variants} transition={Animation.transition}>

        <Helmet><title>{Page.title + " – Matt Argo"}</title></Helmet>

        <BackHome />

        <div>
          <h1 className="spacing-medium-bottom">Don't count your screen reader users.</h1>
          <p className="light-text">The gist: The number of screen reader users on your app(s) shouldn’t determine if it’s worth your time to design for the screen reader experience. That’s because if you haven’t already designed your products to be enjoyable for screen reader users, they are probably avoiding you.</p>
        </div>

        <div className="spacing-medium-top">
          <p>
          The other day I was chatting with a colleague about the complexities of designing good screen reader experiences. They suggested, "Shouldn't we measure how many of our customers actually use screen readers before we start dedicating resources toward this?"
          </p>
          <p>
          This makes sense, at first. Why go through the extra effort of designing for screen reader users if they'll only account for a tiny amount of total users? Couldn't we do something more important with our resources?
          </p>
          <p>
          To be honest – I'm sorry! – my first thought to this type of thing is usually "If our CEO used a screen reader, this wouldn't even be a debate." Numbers tend to feel less objective once you become that number. Especially when they cause you, or someone you care about, to become excluded.
          </p>
          <p>
          Not designing with screen readers in mind is obviously frustrating for current screen reader users. But importantly, this exclusion triggers a chain reaction that causes screen reader users from even becoming customers in the first place. Why would anyone use an app or website that doesn't care about their needs? And if screen reader users aren’t present to be measured...
          </p>
          <p>
          We can't really measure exclusion*.
          </p>
          <p>
          When we count how many current users rely on screen readers (or any other assistive technology) we aren't actually measuring all of the people who would benefit from inclusive design. Instead, we're counting users who, for some reason, have not abandoned us (yet).
          </p>
          <p>
          Counting screen reader users hardly makes sense in any discussion about the importance of increasing our inclusive design obligations. Keeping count is only useful to measure success after we commit to inclusion.
          </p>
        </div>
        <div className="spacing-large-top light-text">
        <p>
        *Theoretically, it is possible to measure exclusion, but it requires analyzing population metrics. Not so straightforward. I found two resources that attempt to do this kind of thing (though they aren't related to inclusive design): <a target="_blank" href="about:blank">International Labor Organization</a> and <a target="_blank" href="https://gsdrc.org/topic-guides/social-exclusion/identifying-exclusion/identifying-and-measuring-social-exclusion/">GSDRC</a>.
        </p>
        </div>
        
      </motion.div>
    );
}

export default DontCountYourScreenReaderUsers;